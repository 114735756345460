<template>
  <b-card>
    <page-header
      name="Personalizar Página"
    >
      <b-button
        variant="outline-primary"
        class="mr-1"
        @click="copyLink"
      >
        <FeatherIcon icon="CopyIcon" />
        &nbsp;<span class="text-nowrap">Copiar Link</span>
      </b-button>
      <b-button
        variant="primary"
        @click="save"
      >
        <FeatherIcon icon="SaveIcon" />
        &nbsp;<span class="text-nowrap">Salvar</span>
      </b-button>
    </page-header>
    <i2-divider />
    <b-card-body>
      <h5>Cores</h5>
      <b-row class="align-items-center">
        <b-col md="2" sm="2" class="text-nowrap">
          Fundo de Botão:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-input
            v-model="model.button.background_color"
            class="input-color"
            type="color"
          />
        </b-col>
        <b-col md="2" sm="2" class="text-nowrap">
          Fundo de Cabeçalho:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-input
            v-model="model.header.background_color"
            class="input-color"
            type="color"
          />
        </b-col>
        <b-col md="2" sm="2" class="text-nowrap">
          Fundo de Rodapé:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-input
            v-model="model.footer.background_color"
            class="input-color"
            type="color"
          />
        </b-col>
        <b-col md="2" sm="2" class="text-nowrap">
          Fundo de Conteúdo:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-input
            v-model="model.content.background_color"
            class="input-color"
            type="color"
          />
        </b-col>
      </b-row>
      <b-row class="align-items-center">
        <b-col md="2" sm="2" class="text-nowrap">
          Fonte de Botão:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-input
            v-model="model.button.text_color"
            class="input-color"
            type="color"
          />
        </b-col>
        <b-col md="2" sm="2" class="text-nowrap">
          Fonte de Cabeçalho:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-input
            v-model="model.header.text_color"
            class="input-color"
            type="color"
          />
        </b-col>
        <b-col md="2" sm="2" class="text-nowrap">
          Fonte de Rodapé:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-input
            v-model="model.footer.text_color"
            class="input-color"
            type="color"
          />
        </b-col>
        <b-col md="2" sm="2" class="text-nowrap">
          Fonte do Conteúdo:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-input
            v-model="model.content.text_color"
            class="input-color"
            type="color"
          />
        </b-col>
      </b-row>
      <i2-divider />
      <h5>Comportamento</h5>
      <b-row class="align-items-center">
        <b-col md="2" sm="2" class="text-nowrap">
          Mostrar Cabeçalho:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-checkbox
            v-model="model.header.show"
            name="model_header_show"
            switch
            inline
          />
        </b-col>
        <b-col md="2" sm="2" class="text-nowrap">
          Mostrar Rodapé:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-checkbox
            v-model="model.footer.show"
            name="model_footer_show"
            switch
            inline
          />
        </b-col>
      </b-row>
      <i2-divider />
      <h5>Formulário</h5>
      <b-row>
        <b-col md="3">
          <i2-form-vue-select
            v-model="model.form.type_coupon"
            label="Forma de Cadastro do Cupom"
            :options="couponTypes"
            name="type_coupon"
          />
        </b-col>
        <b-col md="3">
          <i2-form-vue-select
            v-model="model.form.type_document"
            label="Cadastro por?"
            :options="documentTypesAvailable"
            name="type_document"
          />
        </b-col>
      </b-row>
      <h6>Campos Dinâmicos</h6>
      <b-row>
        <b-col md="3">
          <i2-form-input v-model="model.form.fields[0].label" label="Label do Campo 1" name="name_0" type="text" />
        </b-col>
        <b-col md="3">
          <i2-form-input v-model="model.form.fields[1].label" label="Label do Campo 2" name="name_1" type="text" />
        </b-col>
        <b-col md="3">
          <i2-form-input v-model="model.form.fields[2].label" label="Label do Campo 3" name="name_2" type="text" />
        </b-col>
        <b-col md="3">
          <i2-form-input v-model="model.form.fields[3].label" label="Label do Campo 4" name="name_3" type="text" />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import I2Divider from '@/components/i2/content/i2Divider.vue'

export default {
  name: 'TabVisualPromotion',
  components: { I2Divider },
  props: {
    promotion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      couponTypes: [],
      documentTypesAvailable: [],
      model: {
        header: {
          background_color: '',
          text_color: '',
          header: true,
        },
        footer: {
          background_color: '',
          text_color: '',
          show: true,
        },
        content: {
          background_color: '',
          text_color: '',
        },
        button: {
          background_color: '',
          text_color: '',
        },
        form: {
          type_coupon: { value: 'code', text: 'Código' },
          type_document: null,
          fields: [
            {
              uuid: '12054af9-67e4-4339-b72b-14f6a0c7a4be',
              id: null,
              label: '',
              slug: '',
              type: 'text',
            },
            {
              uuid: 'efc6fdf1-6138-40cf-ab58-2e7914df2498',
              id: null,
              label: '',
              slug: '',
              type: 'text',
            },
            {
              uuid: 'a6436ab6-6a22-4270-991f-e6ec637e2161',
              id: null,
              slug: '',
              label: '',
              type: 'text',
            },
            {
              uuid: 'df574313-9fc2-4850-b10d-e2e9d40b68ef',
              id: null,
              slug: '',
              label: '',
              type: 'text',
            },
          ],
        },
      },
    }
  },
  async mounted() {
    this.model.footer.show = true
    this.model.header.show = true
    await this.getSettings()

    this.couponTypes = [
      {
        value: 'code',
        text: 'Código',
      },
      {
        value: 'photo',
        text: 'Imagem',
      },
    ]
    this.documentTypesAvailable = [
      {
        value: 'cpf-cnpj',
        text: 'CPF e CNPJ',
      },
      {
        value: 'cpf',
        text: 'CPF',
      },
      {
        value: 'cnpj',
        text: 'CNPJ',
      },
    ]
  },
  methods: {
    async getSettings() {
      const response = await this.$http.get(`/promotions-external-settings/${this.$route.params.uuid}`)

      if (response && response.settings) {
        this.model.button = response.settings.button
        this.model.content = response.settings.content
        this.model.footer = response.settings.footer
        this.model.header = response.settings.header
        this.model.form.type_coupon = response.settings.form?.type_coupon
        this.model.form.type_document = response.settings.form?.type_document
        this.model.form.fields = response.settings.form?.fields
      }
    },
    async save() {
      const response = await this.$http.post(`/promotions-external-settings/${this.promotion.uuid}`, this.model)
      if (response && response.erro_status) {
        this.$notify.error('Erro ao salvar as configurações.')
        return
      }
      this.$notify.success('Configurações salvas com sucesso.')
    },
    async copyLink() {
      const response = await this.$http.get(`/promotions-external-settings/${this.$route.params.uuid}/page`)
      const link = `${process.env.VUE_APP_URL}/public/participants?code=&${response}`
      navigator.clipboard.writeText(link)
    },
  },
}
</script>

<style scoped>
.input-color {
  width: 50px !important;
}
</style>
